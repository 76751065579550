.custom-modal {
  position: fixed;
  width: 100%;
  height: calc(100vh - 208px);
  background: rgba(255, 255, 255, 0.2);
  top: 0%;
  left: 0;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  padding: 16px;
  color: #000;
  z-index: 100;
}
.custom-modal-content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.custom-modal-wrapper {
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 560px;
  width: 100%;
  border-radius: 30px;
  padding: 16px 16px;
  text-align: center;
  transition: all 0.3s;
  min-height: 150px;
  padding-bottom: 20px;
}
.custom-modal-wrapper h1 {
  font-size: 32px;
}
.custom-modal.active {
  opacity: 1;
  visibility: visible;
  background: rgb(0 0 0 / 54%);
}

.custom-modal.active .custom-modal-wrapper {
  top: 50%;
}

.custom-modal.network-error .custom-modal-wrapper {
  padding: 25px;
  background-color: #ccc;
  backdrop-filter: blur(100px);
}

.custom-modal.approval .custom-modal-wrapper {
  /* background-color: white; */
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);

  background: #fff;
  border-radius: 10px;
}
.custom-modal.gas-error .custom-modal-wrapper {
  background: transparent linear-gradient( 
    180deg, #b1d8ff 0%, #a0b3ff 100%) 0% 0% no-repeat padding-box;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
}
