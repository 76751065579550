/* To center the spinner*/
.pos-center {
    position: fixed;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    z-index: 1;
  }
  
  .loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }
  
  .loading-text {
    text-shadow: 5px 5px 10px black;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }