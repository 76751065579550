body {
    background-color: #f5f9fc;
    overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #0000;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #b40090bf;
    border-radius: 15px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #b40090fe;
}



.logo {
    img {
        height: 50px;
    }
}

footer {
    bottom: 0;
}

.grad-text {
    background: -webkit-linear-gradient(116deg, #c9d2ff, #008dfd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mainhead {
    line-height: 70px;
    font-family: 'Russo One', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');


.welcome-card {
    backdrop-filter: blur(200px);
    background: rgb(255 255 255);
    box-shadow: 0px 5px 10px #e3e8ed;
}

.grad-btn {
    background: linear-gradient(45deg, #d412a3, #057dd3);
}

.menu-height {
    height: calc(100vh - 115px);
    overflow: auto;
}

.net-bg-opacity {
    background: rgb(244 248 252);
}

.switch *,
*:before,
*:after {
    box-sizing: border-box;
}

.switch__handle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
}

.switch__handle+label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
}

.switch__handle-round-flat+label {
    padding: 2px;
    width: 100px;
    height: 30px;
    background-color: #666666;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    transition: background 0.4s;
}

.switch--large .switch__handle-round-flat+label {
    width: 80px;
    height: 40px;
    border-radius: 40px;
}

.switch__handle-round-flat+label:before,
.switch__handle-round-flat+label:after {
    display: block;
    position: absolute;
    content: "";
}

.switch__handle-round-flat+label:before {
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    background-color: transparent;
    border-radius: 30px;
    transition: background 0.4s;
}

.switch__handle-round-flat+label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 20px;
    background-color: #a5a5a5;
    border-radius: 20px;
    transition: margin 0.4s, background 0.4s;
}

.switch--large .switch__handle-round-flat+label:after {
    width: 26px;
    border-radius: 26px;
    top: 6px;
    left: 6px;
    bottom: 6px;
}

.switch__handle-round-flat:checked+label {
    border-color: #620763;
    background: linear-gradient(45deg, #e615c2, #4777ec);
}

.switch__handle-round-flat:checked+label>.switch__handle__text {
    position: absolute;
    top: 50%;
    right: 11px;
    transform: translateY(-50%);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
}

.switch__handle-round-flat:checked+label:after {
    margin-left: 72px;
    background-color: white;
}

.switch--large .switch__handle-round-flat:checked+label:after {
    margin-left: 40px;
}

.switch__handle__text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.switch__handle__text:before {
    content: 'CONNECT';
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    white-space: nowrap;
    color: #a5a5a5;
}

.switch--large .switch__handle__text:before {
    font-size: 13px;
    right: 13px;
}

.switch__handle-round-flat:checked+label>.switch__handle__text:before {
    content: 'CONNECTED';
    color: white;
    left: 10px;
    right: auto;
    white-space: nowrap;
}

.switch--large .switch__handle-round-flat:checked+label>.switch__handle__text:before {
    left: 14px;
}

.ant-menu-light.ant-menu-inline .ant-menu-item {
    color: #3f3f3f;
    font-weight: bold;
}

.row-minus {
    margin: 0px -10px 0px -10px;
}


@media only screen and (max-width: 767px) {
    .ant-layout-sider {
        flex: 0 0 0 !important;
        max-width: 0 !important;
        min-width: 0 !important;
        width: 0 !important;
        padding: 0 !important;
        position: fixed !important;
        z-index: 99;
    }

    .mv-block {
        display: block !important;
    }

    .sidebar-open {
        .ant-layout-sider {
            flex: 0 0 200px !important;
            max-width: 200px !important;
            min-width: 200px !important;
            width: 200px !important;
            padding: 15px !important;
        }

        .overlay-div {
            display: block;
        }

        .overlay-div {
            background-color: #00000047;
            height: 100vh;
            z-index: 98;
            position: fixed;
            right: 0;
            width: 100%;
        }
    }
}